import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IAdminCreateRolePayload } from '@models/admin/role.model';
import { AxiosError } from 'axios';

export const AdminRoleAPI = {
    getResourceList: async (): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `EndpointQuery/EndpointList`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },
    createRole: async (payload: IAdminCreateRolePayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `RoleCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },
};
