import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ILogoInfo } from './CoreFileLogo';
import { fileStorageAPI } from '@service/file-storage.service';

interface CropImageModalProps {
  visible: boolean;
  imageFile: File | null;
  onCancel: () => void;
  onConfirm: (croppedImage: ILogoInfo) => void;
  maxHeight?: number;
  maxWidth?: number;
  maxSize?: number 

}

const CropImageModal: React.FC<CropImageModalProps> = ({ visible, imageFile, maxSize, maxHeight, maxWidth, onCancel, onConfirm }) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 30,
    height: 30,
    x: 25,
    y: 25,
  });
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const validateSize = (blob: Blob): boolean => {
    const sizeInKB = blob.size / 1024;
    if (maxSize && sizeInKB > maxSize) {
      message.error(`Cropped image size exceeds the limit of ${maxSize}KB.`);
      return false;
    }
    return true;
  };

  const validateAndLoadImage = () => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(imageFile);
    }
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image);
  };

  const getCroppedImg = async (): Promise<Blob | null> => {
    if (!imageRef || !crop.width || !crop.height) return null;

    const canvas = document.createElement('canvas');
    
    // Use crop dimensions as the final canvas size
    canvas.width = crop.width; 
    canvas.height = crop.height; 

    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    // Scale the image to the crop dimensions
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width, // Match canvas size
      canvas.height // Match canvas size
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => resolve(blob), 'image/png');
    });
  };

  const handleConfirm = async () => {
    const croppedBlob = await getCroppedImg();
    if (!croppedBlob) {
      message.error('Failed to crop the image.');
      return;
    }

     // Validate the cropped image size
    if (!validateSize(croppedBlob)) return;

    const base64Image  = await fileStorageAPI.getBase64File(croppedBlob);

    const croppedImageInfo: ILogoInfo = {
      width: crop.width,
      height: crop.height,
      url: base64Image,
    };

    onConfirm(croppedImageInfo);

  };

  React.useEffect(() => {
    if (visible) {
      validateAndLoadImage();
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={handleConfirm}>
          OK
        </Button>,
      ]}
    >
      {imageSrc && (
        <ReactCrop crop={crop} onChange={(newCrop: PixelCrop) => setCrop(newCrop)} maxHeight={maxHeight} maxWidth={maxWidth}>
          <img src={imageSrc} onLoad={(e) => onImageLoaded(e.currentTarget)} alt="Crop" />
        </ReactCrop>
      )}
    </Modal>
  );
};

export default CropImageModal;