import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActionAPI } from '@service/action.service';
import { EResourceType } from '@utils/resourceService';
import { IRole, IUserPermissions } from '@models/admin/user.model';

interface IUserState {
    email: string;
    permissions: IUserPermissions | undefined;
}

const initialState: IUserState = {
    email: '',
    permissions: undefined,
};

export const getResourcePaths = createAsyncThunk(
    'resource-path',
    async (fromService: EResourceType) => {
        try {
            const response = await ActionAPI.getResource(fromService);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const UserSlice = createSlice({
    name: 'admin-user-slice',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPermissions: (state, action: PayloadAction<IUserPermissions>) => {
            state.permissions = action.payload;
        },
        resetUser: () => initialState,
    },
    extraReducers: () => {},
});

export const { setEmail, resetUser, setPermissions } = UserSlice.actions;

export default UserSlice.reducer;
