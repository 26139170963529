import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdminRoleAPI } from '@service/admin/role.service';

export interface IAdminRoleState {
    resourceList: {
        data: [];
        isLoading: boolean;
    };
}

const initialState: IAdminRoleState = {
    resourceList: {
        data: [],
        isLoading: false,
    },
};

export const getResourceList = createAsyncThunk('getResourceList', async () => {
    try {
        const response = await AdminRoleAPI.getResourceList();
        return response;
    } catch (error: any) {
        throw error?.response?.data?.Message || error?.message;
    }
});

const adminRoleSlice = createSlice({
    name: 'admin-role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getResourceList.pending, (state) => {
                state.resourceList.isLoading = true;
            })
            .addCase(getResourceList.fulfilled, (state, action) => {
                state.resourceList.isLoading = false;
                state.resourceList.data = action.payload;
            })
            .addCase(getResourceList.rejected, (state) => {
                state.resourceList.isLoading = false;
                state.resourceList.data = [];
            });
    },
});

export default adminRoleSlice.reducer;
