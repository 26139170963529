import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
    IEmailConfiguration,
    IEmailTemplate,
    IGetAdminTemplatesPayload,
    ISingleAdminEmailTemplatePayload,
    ISingleAdminTemplatePayload,
    ITemplate,
} from '@models/admin/template.model';
import { AdminTemplateAPI } from '@service/admin/template.service';
import { RootState } from '@store/store';
import _ from 'lodash';

interface IAdminTemplateState {
    pdfTemplates: {
        isLoading: boolean;
        data: ITemplate[] | [];
    };
    pdfTemplate: {
        isLoading: boolean;
        data: ITemplate | undefined;
    };
    emailTemplates: {
        isLoading: boolean;
        data: IEmailTemplate[] | [];
    };
    emailTemplate: {
        isLoading: boolean;
        data: IEmailTemplate | undefined;
    };
    emailConfigurations: {
        isLoading: boolean;
        data: IEmailConfiguration[] | undefined;
    };
}

const initialState: IAdminTemplateState = {
    pdfTemplates: {
        isLoading: false,
        data: [],
    },
    pdfTemplate: {
        isLoading: false,
        data: undefined,
    },
    emailTemplates: {
        isLoading: false,
        data: [],
    },
    emailTemplate: {
        isLoading: false,
        data: undefined,
    },
    emailConfigurations: {
        isLoading: false,
        data: undefined,
    },
};

export const getPdfTemplates = createAsyncThunk(
    'getPdfTemplates',
    async (payload: IGetAdminTemplatesPayload) => {
        try {
            const response = await AdminTemplateAPI.getPdfTemplates(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getPdfTemplate = createAsyncThunk(
    'getPdfTemplate',
    async (payload: ISingleAdminTemplatePayload) => {
        try {
            const response = await AdminTemplateAPI.getPdfTemplate(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getEmailTemplates = createAsyncThunk('getEmailTemplates', async () => {
    try {
        const response = await AdminTemplateAPI.getEmailTemplates();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getEmailConfigurations = createAsyncThunk('getEmailConfigurations', async () => {
    try {
        const response = await AdminTemplateAPI.getEmailConfigurations();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getEmailTemplate = createAsyncThunk(
    'getEmailTemplate',
    async (payload: ISingleAdminEmailTemplatePayload) => {
        try {
            const response = await AdminTemplateAPI.getEmailTemplate(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const AdminTemplate = createSlice({
    name: 'adminTemplate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPdfTemplates.pending, (state) => {
                state.pdfTemplates.isLoading = true;
            })
            .addCase(getPdfTemplates.fulfilled, (state, action) => {
                state.pdfTemplates.isLoading = false;
                state.pdfTemplates.data = action.payload.Templates;
            })
            .addCase(getPdfTemplates.rejected, (state) => {
                state.pdfTemplates.isLoading = true;
            })

            .addCase(getPdfTemplate.pending, (state) => {
                state.pdfTemplate.isLoading = true;
            })
            .addCase(getPdfTemplate.fulfilled, (state, action) => {
                state.pdfTemplate.isLoading = false;
                state.pdfTemplate.data = action.payload;
            })
            .addCase(getPdfTemplate.rejected, (state) => {
                state.pdfTemplate.isLoading = true;
            })

            .addCase(getEmailTemplates.pending, (state) => {
                state.emailTemplates.isLoading = true;
            })
            .addCase(getEmailTemplates.fulfilled, (state, action) => {
                state.emailTemplates.isLoading = false;
                state.emailTemplates.data = action.payload;
            })
            .addCase(getEmailTemplates.rejected, (state) => {
                state.emailTemplates.isLoading = false;
            })

            .addCase(getEmailTemplate.pending, (state) => {
                state.emailTemplate.isLoading = true;
            })
            .addCase(getEmailTemplate.fulfilled, (state, action) => {
                state.emailTemplate.isLoading = false;
                state.emailTemplate.data = action.payload;
            })
            .addCase(getEmailTemplate.rejected, (state) => {
                state.emailTemplate.isLoading = false;
            })

            .addCase(getEmailConfigurations.pending, (state) => {
                state.emailConfigurations.isLoading = true;
            })
            .addCase(getEmailConfigurations.fulfilled, (state, action) => {
                state.emailConfigurations.isLoading = false;
                state.emailConfigurations.data = action.payload;
            })
            .addCase(getEmailConfigurations.rejected, (state) => {
                state.emailConfigurations.isLoading = false;
            });
    },
});

const selectEmailConfigurations = (state: RootState) =>
    state.adminTemplate.emailConfigurations.data;

export const MailConfigurationOptions = createSelector(
    [selectEmailConfigurations],
    (configurations) =>
        configurations?.map((config: any) => ({
            value: config.ConfigurationId,
            label: config.SmtpHost,
        })),
);

const selectTemplates = (state: RootState) => state.adminTemplate.pdfTemplates.data;

export const simplifiedTemplatesOptions = createSelector(selectTemplates, (templates) =>
    _.chain(templates)
        .filter({ TemplateType: 'Simplified' })
        .map((template) => ({
            label: template.TemplateName,
            value: template.TemplateId,
            previewId: template.PreviewTemplatePdfId,
        }))
        .value(),
);

export const standardTemplatesOptions = createSelector(selectTemplates, (templates) =>
    _.chain(templates)
        .filter({ TemplateType: 'Standard' })
        .map((template) => ({
            label: template.TemplateName,
            value: template.TemplateId,
            previewId: template.PreviewTemplatePdfId,
        }))
        .value(),
);

export default AdminTemplate.reducer;
