import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { AxiosError } from 'axios';

export const AdminPersonaAPI = {
    getPersonas: async (): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `PersonaQuery/Personas`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Personas;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
