import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const NumberOneSvg = () => (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='40' height='40' rx='20' fill='#25A67E' />
        <path
            d='M21.7528 14.3636V26H19.9915V16.125H19.9233L17.1392 17.9432V16.2614L20.0426 14.3636H21.7528Z'
            fill='white'
        />
    </svg>
);
const NumberOneIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={NumberOneSvg} {...props} />
);

export default NumberOneIcon;
