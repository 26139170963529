import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IWhatsNewGetPayload, IWhatsNewResponse, IWhatsNewPayload, IWhatsNewDetailsPayload } from '@models/whatsNew.model';

export const WhatsNewAPI = {
    getWhatsNewReadAll: async (payload: IWhatsNewGetPayload): Promise<IWhatsNewResponse> => {
        const url = APP_CONFIG.eisUrl + `WhatsNew/ReadAll`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data as IWhatsNewResponse;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    deleteWhatsNew: async (payload: { TimeStamp: number }): Promise<void> => {
        const url = APP_CONFIG.eisUrl + `WhatsNew/Delete`;
        try {
            await AXIOS_INSTANCE.post<any>(url, payload);
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    whatsNewDetail: async (timeStamp: number): Promise<IWhatsNewDetailsPayload> => {
        const url = `${APP_CONFIG.eisUrl}WhatsNew/Read?TimeStamp=${timeStamp}`;
        try {
            const response = await AXIOS_INSTANCE.get<IWhatsNewDetailsPayload>(url);
            return response?.data as IWhatsNewDetailsPayload;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updateWhatsNew: async (payload: IWhatsNewPayload): Promise<void> => {
        const url = APP_CONFIG.eisUrl + `WhatsNew/Update`;
        try {
            await AXIOS_INSTANCE.post<any>(url, payload);
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createWhatsNew: async (payload: IWhatsNewPayload): Promise<void> => {
        const url = APP_CONFIG.eisUrl + `WhatsNew/Create`;
        try {
            await AXIOS_INSTANCE.post<any>(url, payload);
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
