import { IPersona } from '@models/admin/persona.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdminPersonaAPI } from '@service/admin/persona.service';

export interface IPersonatSate {
    personas: {
        data: IPersona[];
        isLoading: boolean;
    };
}

const initialState: IPersonatSate = {
    personas: {
        data: [],
        isLoading: false,
    },
};

export const getPersons = createAsyncThunk('getPersons', async () => {
    try {
        const response = await AdminPersonaAPI.getPersonas();
        return response;
    } catch (error: any) {
        throw error?.response?.data?.Message || error?.message;
    }
});

const personaSlice = createSlice({
    name: 'admin-role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPersons.pending, (state) => {
                state.personas.isLoading = true;
            })
            .addCase(getPersons.fulfilled, (state, action) => {
                state.personas.isLoading = false;
                state.personas.data = action.payload;
            })
            .addCase(getPersons.rejected, (state) => {
                state.personas.isLoading = false;
                state.personas.data = [];
            });
    },
});

export default personaSlice.reducer;
