import { PaymentCalculatorType } from '@store/slices/paymentCalculator';

type IEstimationItemData = {
    title: string;
    description: string;
    type: PaymentCalculatorType;
};
export const estimationItemsData: IEstimationItemData[] = [
    {
        type: 'invoices',
        title: 'Invoices',
        description:
            "A secure and customizable compute service that lets you create and run virtual machines on Google's infrastructure. Compute Engine includes the following services: Instances, Sole-tenant Nodes, Machine Images.",
    },
    {
        title: 'Organizations',
        description:
            'Cloud Storage is a scalable, reliable, and secure cloud storage service for businesses of all sizes...',
        type: 'organizations',
    },
    {
        title: 'Entities',
        description:
            'Google Cloud SQL is a fully managed database service that helps you easily deploy, manage, and scale your relational databases...',
        type: 'entities',
    },
    {
        title: 'Devices',
        description:
            'BigQuery is a fully managed, petabyte-scale analytics data warehouse that enables businesses to analyze all their data very quickly...',
        type: 'devices',
    },
    {
        title: 'Users',
        description:
            'Google Cloud TPUs are custom-designed AI accelerators, which are optimized for training and inference of large AI models.',
        type: 'users',
    },
    {
        title: 'Emails',
        description:
            'Google Cloud TPUs are custom-designed AI accelerators, which are optimized for training and inference of large AI models.',
        type: 'emails',
    },
];

const notHoursType: PaymentCalculatorType[] = ['emails', 'invoices'];

export function getAmountTypeAndRateByDataType(
    type: PaymentCalculatorType,
    amount: number,
    hours: number,
): { isHoursRate: boolean; amount: number } {
    if (notHoursType.includes(type)) {
        return {
            isHoursRate: false,
            amount: amount,
        };
    }
    return {
        isHoursRate: true,
        amount: hours * amount,
    };
}
