import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { estimationItemsData, getAmountTypeAndRateByDataType } from '@utils/estimationItemData';
import { getPayAsGoAmountByItemType } from '@utils/getPayAsGoByItemType';
import _ from 'lodash';
import { selectPayAsYouGoInfo } from './subscribeSlice';

interface IPaymentCalculatorState {
    invoices?: ICalculatorInfo;
    organizations?: ICalculatorInfo;
    users?: ICalculatorInfo;
    entities?: ICalculatorInfo;
    devices?: ICalculatorInfo;
    emails?: ICalculatorInfo;
}

interface ICalculatorInfo {
    count: number;
    hours: number;
}

export type PaymentCalculatorType = keyof IPaymentCalculatorState;

const initialState: IPaymentCalculatorState = {};

export const PaymentCalculator = createSlice({
    name: 'paymentCalculator',
    initialState,
    reducers: {
        removeCalculatorItem: (state, action: { type: string; payload: PaymentCalculatorType }) => {
            const keyName = action.payload;
            if (keyName in state) {
                delete state[keyName];
            }
        },
        addNewCalculatorItem: (state, action: { type: string; payload: PaymentCalculatorType }) => {
            const keyName = action.payload;
            state[keyName] = { count: 1, hours: 50 } as ICalculatorInfo;
        },
        changeCalculatorItem: (
            state,
            action: { type: string; payload: { name: PaymentCalculatorType; count: number } },
        ) => {
            const { name, count } = action.payload;
            const items = state[name];
            if (items) {
                items.count = count;
            }
        },

        changeUsageTime: (
            state,
            action: { type: string; payload: { name: PaymentCalculatorType; hours: number } },
        ) => {
            const { name, hours } = action.payload;
            const items = state[name];
            if (items) {
                items.hours = hours;
            }
        },
    },
});
export const { removeCalculatorItem, addNewCalculatorItem, changeCalculatorItem, changeUsageTime } =
    PaymentCalculator.actions;

export const selectPaymentCalculator = (state: RootState) => state.paymentCalculator;

export const selectPaymentCalculatorList = createSelector(
    [selectPaymentCalculator, selectPayAsYouGoInfo],
    (calculator, payAsYouGo) => {
        // remove empty calculator stateValue
        const filteredState = _.pickBy(
            calculator,
            (value) => value !== undefined && value !== null,
        );

        // if found empty object then return empty array
        if (_.isEmpty(filteredState)) {
            return [];
        }
        return _.map(filteredState, (value, key) => {
            const amount = getPayAsGoAmountByItemType(key as PaymentCalculatorType, payAsYouGo);
            const count = value?.count;
            const hours = value?.hours;

            const amountFormat = getAmountTypeAndRateByDataType(
                key as PaymentCalculatorType,
                amount,
                hours || 0,
            );

            return {
                key,
                value: count,
                amount,
                hours: hours,
                total: amountFormat.amount * (count || 0),
                isHoursRate: amountFormat.isHoursRate,
            } as IPricingCalculatorAmount;
        });
    },
);

export const selectIsPaymentCalculatorFound = createSelector(
    [selectPaymentCalculator],
    (calculator): boolean => {
        return _.some(calculator, (value) => value !== undefined && value !== null);
    },
);

export const selectEstimationData = createSelector([selectPaymentCalculator], (calculator) => {
    const calculatorKeys = Object.keys(calculator);
    return estimationItemsData.filter((item) => !calculatorKeys.includes(item.type));
});

export default PaymentCalculator.reducer;

export interface IPricingCalculatorAmount {
    key: PaymentCalculatorType;
    value: number;
    amount: number;
    total: number;
    hours?: number;
    isHoursRate: boolean;
}

export type IPricingCalculatorAmountList = IPricingCalculatorAmount[];
