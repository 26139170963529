import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IInvoiceCreation, IInvoicePayload, IInvoiceResult, IDraftInvoiceResult } from '@models/invoice.model';
import { AxiosError } from 'axios';
import { processQuery } from '@utils/processQuery';

export const INVOICE_API = {
    createInvoice: async (payload: IInvoiceCreation): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    previewInvoice: async (payload: IInvoiceCreation): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/Preview`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            }, {responseType: 'arraybuffer'});
            const file = new Blob([response?.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            return fileURL;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    saveAsDraft: async (payload: IInvoiceCreation): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/SaveAsDraft`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getAllDraftInvoices: async (payload: IInvoicePayload): Promise<IDraftInvoiceResult> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/GetDraftInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    deleteDraftInvoice: async (payload: {itemId: string}): Promise<void> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/DeleteDraft`;
        try {
            await AXIOS_INSTANCE.post<any>(url, payload);
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getAllInvoices: async (payload: IInvoicePayload): Promise<IInvoiceResult> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/GetInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getInvoice: async (invoiceId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceQuery/GetInvoice${processQuery({ invoiceId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getDraftInvoice: async (ItemId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceQuery/GetDraftInvoice${processQuery({ ItemId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    sendInvoiceByMail: async (payload: any): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/SendInvoice`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    addInvoiceNote: async (payload: any): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/AddNote`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    uploadCSVFile: async (
        file: File,
        data: { OrganizationId: string; DeviceId: string; EntityId: string },
    ): Promise<any> => {
        try {
            const url = APP_CONFIG.eisUrl + 'CsvCommand/UploadCsv';
            const bodyFormData = new FormData();
            bodyFormData.append('File', file);
            bodyFormData.append('OrganizationId', data?.OrganizationId);
            bodyFormData.append('FileName', file.name);
            bodyFormData.append('DeviceId', data?.DeviceId);
            bodyFormData.append('EntityId', data?.EntityId);

            const response = await AXIOS_INSTANCE.post(url, bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.Result;
        } catch (error) {
            console.error('upload failed test', { error });
            throw error;
        }
    },

    getUploadCSVFiles: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvQuery/GetMapConfigs`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { PageLimit: 1000 });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getUploadCSVFileById: async (mapConfigId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvQuery/GetMapConfig` + processQuery({ mapConfigId });
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    saveMapping: async (payload: any): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvCommand/SaveMappingConfig`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    submitInvoices: async (payload: any): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvCommand/SubmitInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updatePaymentStatus: async (payload: {
        ItemId: string;
        PaymentStatus: string;
    }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/UpdatePaymentStatus`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    updateLock: async (payload: { ItemId: string; isLocked: boolean }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/UpdateLock`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },
};
