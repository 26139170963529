import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    IAssignEnterpriseSubscription,
    ISubscriptionInvoicePayload,
    ISubscriptionResponse,
    ISubscriptionStatusPayload,
} from '@models/admin/subscription.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const AdminSubscriptionAPI = {
    GetSubscriptionInvoices: async (
        SubscriptionId: string,
        payload: ISubscriptionInvoicePayload,
    ) => {
        const url =
            APP_CONFIG.eisUrl +
            `SubscriptionCommand/Invoices${processQuery({ SubscriptionId: SubscriptionId })}`;

        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionInvoice: async (payload: { SubscriptionId: string; InvoiceId: string }) => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Invoice${processQuery(payload)}`;

        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetAdminSubscriptions: async (payload: any) => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Subscriptions`;
        try {
            const response = await AXIOS_INSTANCE.post<ISubscriptionResponse>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetAdminSubscription: async (payload: ISubscriptionStatusPayload) => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Subscription${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    LockSubscription: async (payload: ISubscriptionStatusPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Lock`;
        try {
            const response = await AXIOS_INSTANCE.post<ISubscriptionStatusPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    UnlockSubscription: async (payload: { SubscriptionId: string }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Unlock`;
        try {
            const response = await AXIOS_INSTANCE.post<ISubscriptionStatusPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },

    EnterprisePlan: async (payload: IAssignEnterpriseSubscription): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Enterprise`;
        try {
            const response = await AXIOS_INSTANCE.post<IAssignEnterpriseSubscription>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },
};
