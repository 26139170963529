import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IWhatsNewResponse, IWhatsNewGetPayload, IWhatsNewPayload } from '@models/whatsNew.model';
import { WhatsNewAPI } from '@service/whatsNew.service';

interface IWhatsNewState {
    updates: {
        status: RequestStatus;
        data: IWhatsNewResponse;
        details: IWhatsNewPayload;
    };
}

const initialState: IWhatsNewState = {
    updates: {
        status: 'idle',
        data: {
            WhatsNew: [],
            Next: '',
            Nonce: '',
            Count: 0,
        },
        details: {},
    },
};

export const getWhatsNewReadAll = createAsyncThunk(
    'getWhatsNewReadAll',
    async (payload: IWhatsNewGetPayload) => {
        try {
            const response = await WhatsNewAPI.getWhatsNewReadAll(payload);
            return {...response, isConcat: payload.isConcat};
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const deleteWhatsNew = createAsyncThunk(
    'deleteWhatsNew',
    async (payload: { TimeStamp: number }, { rejectWithValue }) => {
        try {
            const response = await WhatsNewAPI.deleteWhatsNew(payload);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const whatsNewDetail = createAsyncThunk('whatsNewDetail', async (timeStamp: number) => {
    try {
        const response = await WhatsNewAPI.whatsNewDetail(timeStamp);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const updateWhatsNew = createAsyncThunk(
    'updateWhatsNew',
    async (payload: IWhatsNewPayload) => {
        try {
            await WhatsNewAPI.updateWhatsNew(payload);
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const createWhatsNew = createAsyncThunk(
    'createWhatsNew',
    async (payload: IWhatsNewPayload) => {
        try {
            await WhatsNewAPI.createWhatsNew(payload);
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const WhatsNewSlice = createSlice({
    name: 'WhatsNewSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWhatsNewReadAll.pending, (state) => {
                state.updates.status = 'loading';
            })
            .addCase(getWhatsNewReadAll.rejected, (state) => {
                state.updates.status = 'failed';
                state.updates.data.WhatsNew = [];
            })
            .addCase(getWhatsNewReadAll.fulfilled, (state, action) => {
                state.updates.status = 'idle';
                const {WhatsNew = [], Next, Nonce, isConcat} = action.payload;

                const hasNext = !!Next;
                state.updates.data.Next = Next;
                state.updates.data.Nonce = hasNext ? Nonce : undefined;
                state.updates.data.WhatsNew = isConcat
                    ? state.updates.data.WhatsNew.concat(WhatsNew as IWhatsNewPayload[] || [])
                    : WhatsNew;
            })
            .addCase(deleteWhatsNew.pending, (state) => {
                state.updates.status = 'loading';
            })
            .addCase(deleteWhatsNew.rejected, (state) => {
                state.updates.status = 'failed';
            })
            .addCase(deleteWhatsNew.fulfilled, (state, action) => {
                state.updates.status = 'idle';
                const { TimeStamp } = action.meta.arg;
                state.updates.data.WhatsNew = state.updates.data.WhatsNew.filter(
                    (item) => item.TimeStamp !== TimeStamp,
                );
            })
            .addCase(whatsNewDetail.pending, (state) => {
                state.updates.status = 'loading';
            })
            .addCase(whatsNewDetail.rejected, (state) => {
                state.updates.status = 'failed';
            })
            .addCase(whatsNewDetail.fulfilled, (state, action) => {
                state.updates.status = 'idle';
                state.updates.details = action.payload.Result || {};
            })
            .addCase(updateWhatsNew.pending, (state) => {
                state.updates.status = 'loading';
            })
            .addCase(updateWhatsNew.rejected, (state) => {
                state.updates.status = 'failed';
            })
            .addCase(updateWhatsNew.fulfilled, (state) => {
                state.updates.status = 'idle';
            })
            .addCase(createWhatsNew.pending, (state) => {
                state.updates.status = 'loading';
            })
            .addCase(createWhatsNew.rejected, (state) => {
                state.updates.status = 'failed';
            })
            .addCase(createWhatsNew.fulfilled, (state) => {
                state.updates.status = 'idle';
            });
    },
});
export const whatsNewData = (state: RootState) => state.whatsNew.updates;

export default WhatsNewSlice.reducer;
