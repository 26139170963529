import {
    BookOutlined,
    BorderOuterOutlined,
    FileTextOutlined,
    PicCenterOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { getItem, MenuItem } from './menu-items';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
export const AdminMenuItems = (t: TFunction<'translation', undefined>): MenuItem[] =>
    [
        getItem(<div className='full-width-height'>IAM</div>, 'user-management', <UserOutlined />, [
            getItem(
                <Link to='/admin/iam/users' className='justify-between'>
                    <div>User Managements</div>
                </Link>,
                'users',
            ),
            getItem(
                <div className='full-width-height'>Roles & Permission</div>,
                'roles-permission',
                <UserOutlined />,
                [
                    getItem(
                        <Link to='/admin/iam/resource-group' className='justify-between'>
                            <div>Resources Group</div>
                        </Link>,
                        'resources-group',
                    ),
                    getItem(
                        <Link to='/admin/iam/role' className='justify-between'>
                            <div>Roles</div>
                        </Link>,
                        'roles',
                    ),
                ],
            ),
        ]),
        getItem(
            <div className='full-width-height'>Subscription Management</div>,
            'subscription-management',
            <BorderOuterOutlined />,
            [
                getItem(
                    <Link to='/admin/subscription' className='justify-between'>
                        <div>Subscriptions</div>
                    </Link>,
                    'subscriptions',
                ),
                getItem(
                    <Link to='/admin/subscription/tiers' className='justify-between'>
                        <div>Tiers</div>
                    </Link>,
                    'tiers',
                ),
                getItem(
                    <Link to='/admin/subscription/coupons' className='justify-between'>
                        <div>Coupons</div>
                    </Link>,
                    'coupons',
                ),
                getItem(
                    <Link to='/admin/subscription/configuration' className='justify-between'>
                        <div>Configuration</div>
                    </Link>,
                    'configuration',
                ),
            ],
        ),
        getItem(
            <div className='full-width-height'>Templates</div>,
            'templates',
            <PicCenterOutlined />,
            [
                getItem(
                    <Link to='/admin/template/email' className='justify-between'>
                        <div>Email</div>
                    </Link>,
                    'email',
                ),
                getItem(
                    <Link to='/admin/template/pdf' className='justify-between'>
                        <div>PDF</div>
                    </Link>,
                    'pdf',
                ),
            ],
        ),
        getItem(<Link to='/admin/whats-new'>{t('WHATS_NEW')}</Link>, 'whats-new', <BookOutlined />),
    ].filter(Boolean);
