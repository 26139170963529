import { ISubscriptionPricing } from '@models/subscription.model';
import { PaymentCalculatorType } from '@store/slices/paymentCalculator';

export function getPayAsGoAmountByItemType(
    type: PaymentCalculatorType,
    payAsGo: ISubscriptionPricing,
) {
    if (type === 'invoices') {
        return payAsGo?.InvoicePrice || 0;
    }
    if (type === 'devices') {
        return payAsGo?.DevicePrice || 0;
    }
    if (type === 'entities') {
        return payAsGo?.EntityPrice || 0;
    }
    if (type === 'organizations') {
        return payAsGo?.OrganizationPrice || 0;
    }

    if (type === 'users') {
        return payAsGo?.UserPrice || 0;
    }

    if (type === 'emails') {
        return payAsGo?.EmailPrice || 0;
    }

    return 0;
}
